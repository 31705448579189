<template>
  <div class="setting-feedback">
    <div class="form">
      <van-field
        v-model="content"
        rows="6"
        autosize
        class="textarea"
        type="textarea"
        maxlength="200"
        placeholder="感谢您对猫嘀嘀的支持，猫嘀嘀的产品会尽快回复您的问题！"
        show-word-limit
      />
    </div>
    <div class="footer">
      <van-button type="primary" size="normal" round block color="linear-gradient(136deg, #FFB367 0%, #FF7700 100%)" @click="goSubmit">提交</van-button>
    </div>
  </div>
</template>

<script>
import { saveSuggest } from '@/api/user'
export default {
  data() {
    return {
      content: ''
    }
  },
  methods: {
    async goSubmit() {
      if (!this.content) return this.$toast('请输入内容！')
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const res = await saveSuggest({ content: this.content })
      loading.clear()
      this.$toast({
        message: res.resp_msg || '操作成功!',
        duration: 1000,
        onClose: () => {
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.setting-feedback {
  min-height: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  background: #f7f8fa;
  .form {
    padding: 16px;
    background: #fff;
    .textarea {
      background: #fafafa;
    }
  }
  .footer {
    padding: 40px;
  }
}
</style>
